@media (max-width: 686px) {
    .bx--progress--vertical .bx--progress-label {
        max-width: none;
    }

    .bx--progress--vertical .bx--progress-step, .bx--progress--vertical .bx--progress-step-button {
        min-height: 1.75rem;
        align-content: center;
    }

    .bx--progress--vertical .bx--progress-step svg, .bx--progress--vertical .bx--progress-step-button svg {
        margin-top: 4px;
        
    }
}