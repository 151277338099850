.help-modal, .verify-phone-modal, .promoted-product-modal {
    .bx--modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 16px;
        margin-bottom: 16px;
    }

    .bx--text-input {
        background-color: #f4f4f4;
    }
    
    .bx--modal-close {
        border-width: 0;
    }
}
