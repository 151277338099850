.show-more-accordion {    
    .bx--accordion__item:last-child {
        border: none;
    }

    .bx--accordion__title {
        font-weight: 600;
    }

    .bx--accordion__heading:hover {
        background-color: transparent;
    }
    
    .bx--accordion__heading:hover::before {
        background-color: transparent;
    }
}

.show-more-accordion {
    margin-bottom: 12px;
}