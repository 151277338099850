.qa-accordion {
    padding-top: 32px;
}

@media (min-width: 686px) {
    .qa-accordion {
        padding: 20px;
        padding-top: 32px;
    }
}

.qa-accordion {
    .bx--accordion__title {
        font-weight: 500;
        font-size: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    @media (min-width: 686px) {
        .bx--accordion__title {
            font-weight: 500;
            font-size: 20px;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
    
    .bx--accordion__heading {
        align-items: center;
    }
    
    .bx--accordion__heading:focus {
        outline: 0;
    }
    
    .bx--accordion__heading:hover {
        background-color: #33845788;
        border-radius: 4px;
        color: #fff,
    }
    
    .bx--accordion__heading:hover::before {
        background-color: #33845788;
        border-radius: 4px;
        color: #fff,
    }
    
    .bx--accordion__heading:focus::before {
        border: 0;
    }
    
    .bx--accordion__arrow {
        size: 24px;
    }
    
    .bx--accordion__item--active button {
        background-color: #338458;
        border-radius: 4px;
    }
    
    .bx--accordion__item--active .bx--accordion__heading {
        color: #fff;
    }

    .bx--accordion__content {
        padding-right: 16px !important;
        padding-left: 16px !important;
    }
}
