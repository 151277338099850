@mixin button-modal-upload {
    background: #FFFFFF;
    border: 1px solid #E2EBEB;
    border-radius: 4px;
    color: #338458;
    font-size: 15px;
    font-weight: 600;
    padding-left: 16px;
    padding-right: 16px;
}

.upload-modal .bx--btn--upload {
    @include button-modal-upload;
}

.upload-modal .bx--form-item {
    align-items: center;
}

.upload-modal .bx--file__selected-file {
    background-color: #fff;
}

.bx--btn--ghost {
    @include button-modal-upload;
    &:hover {
        @include button-modal-upload; 
    }
}

