@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;700;800&display=swap');

$black: #212623;
$orange: #F26133;
$green: #00652E;

body {
  font-family: 'Work Sans', sans-serif;
}

input[type=number] {
  font-family: 'Work Sans', sans-serif;
}

.bx--slider__thumb {
  background: $orange;
  height: 1.5rem;
  width: 1.5rem;
}

.bx--slider__filled-track {
  background: $orange;
  height: 5.1px;
  border-radius: 6px;
}

.bx--slider__track {
  background: #DCDCDC;
  height: 3.4px;
  border-radius: 6px;
}

.bx--slider__thumb:focus ~ .bx--slider__filled-track {
  background: $orange;
}

.bx--slider__thumb:focus {
  background-color: $orange;
  box-shadow: inset 0 0 0 2px $orange, inset 0 0 0 3px #f4f4f4;
}

.bx--progress {
  justify-content: center;
}

.bx--progress-label {
  max-width: 256px;
  padding-right: 20px;
  font-size: 16px;
  color: $black;
  margin-top: 6.4px;
}

.bx--progress-step {
  width: fit-content;
}

.bx--progress-line {
  width: 100%;
}

.bx--progress-step svg {
  fill: $green;
}

.bx--progress-line {
  background-color: $green;
}

.bx--progress-step--current .bx--progress-line,
.bx--progress-step--complete .bx--progress-line {
  background-color: $green;
}

.bx--btn--primary:focus {
  box-shadow: none;
}

.bx--modal-container {
  background-color: #FFFFFF;
  box-shadow: 0px 3.40014px 85.0036px rgba(38, 89, 61, 0.13);
  border-radius: 16px;
}

.bx--select-input:focus,
.bx--text-input:focus,
.bx--date-picker__input:focus,
.bx--checkbox:focus + .bx--checkbox-label::before, .bx--checkbox-label__focus::before, .bx--checkbox:checked:focus + .bx--checkbox-label::before, .bx--checkbox-label[data-contained-checkbox-state=true].bx--checkbox-label__focus::before, .bx--checkbox:indeterminate:focus + .bx--checkbox-label::before, .bx--checkbox-label[data-contained-checkbox-state=mixed].bx--checkbox-label__focus::before {
  outline-color: $green;
}

.bx--radio-button:focus + .bx--radio-button__label .bx--radio-button__appearance {
  box-shadow: 0 0 0 2px $green;
}

.bx--accordion__heading:focus::before {
  border: none;
}

// .shell {
//   position: relative;
//   line-height: 1;
//   span {
//   position: absolute;
//   left: 3px;
//   top: 1px;
//   color: #ccc;
//   pointer-events: none;
//   z-index: -1;
//     i { 
//         font-style: normal;
//       /* any of these 3 will work */
//         color: transparent;
//         opacity: 0;
//         visibility: hidden;
//     }
//   }
// }

// input.masked,
// .shell span {
//   font-size: 16px;
//   font-family: monospace;
//   padding-right: 10px;
//   background-color: transparent;
//   text-transform: uppercase;
// }